const HeadlineData = [
  {
    id: 1,
    text: "Barber shop on the highest floor claims to be a cut above the rest",
  },
  {
    id: 2,
    text: "Pest buster app finally updated with bug fixes",
  },
  {
    id: 3,
    text: "Time-travelling drummer facing serious repercussions",
  },
  {
    id: 4,
    text: "Michelin chef taking a day off after pulling a hamstring",
  },
  {
    id: 5,
    text: "Hell's kitchen airs half-episode after running out of thyme",
  },
  {
    id: 6,
    text: "Farmer with broken fence set out on a wild goose chase",
  },
  {
    id: 7,
    text:
      "Experts believe Da Vinci's Last Supper painting leads to the holy grill",
  },
  {
    id: 8,
    text: "Mother hosts baby shower after giving birth in the bathroom",
  },
  {
    id: 9,
    text:
      "Arrogant swindler jumps to his death. Police found con-descending evidence",
  },
  {
    id: 10,
    text: "Charitable porn stars said to be working on a pro-boner basis",
  },
  {
    id: 11,
    text:
      "Man caught by wife at an orgy claims he gave in to intense peer pleasure",
  },
  {
    id: 12,
    text: "College baseball recruiter currently working on his elevator pitch",
  },
  {
    id: 13,
    text:
      "Fashion designer creates a belt of watches but finds it to be a total waist of time",
  },
  {
    id: 99,
    text:
      "Thank you for visiting drew's site. If you'd like to see your headline featured, kindly email dreworkz@gmail.com",
  },
];

export default HeadlineData;
