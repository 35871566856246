import React from "react";
import ReactDOM from "react-dom";
import { setGlobal } from "reactn";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import App from "./components/App";

setGlobal({
  activeProject: "All",
});

ReactDOM.render(<App />, document.getElementById("root"));
