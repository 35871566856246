import React from "react";
import styled from "styled-components";

import ButtonText from "../DLS/text";
import PrimaryButton from "../DLS/primary-button";
import GhostButton from "../DLS/ghost-button";

const ProjectContainer = styled.div`
  font-family: "Signika Negative";
  font-size: 15px;
  margin-top: 18px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Text = styled.div`
  font-weight: 400;
  line-height: 1.5;
`;

const ProjectDisplay = () => {
  return (
    <ProjectContainer>
      <Title>Hello World A.I. You Ready</Title>
      <br />
      <Text>
        I was tasked to design an interesting media invitation in line with the
        theme of 'Artificial Intelligence' for the Singapore Science Festival
        2018.
      </Text>
      <br />
      <Text>
        The result was a QR code puzzle that teaches you how the programme reads
        the shapes &amp; information as you build it. Once finished, scanning
        the QR code with your phone will take you to the event sign up page
        where you can learn more about the festival.
      </Text>
      <br />
      <Text>
        Hello World. A.I. You Ready? was awarded a ‘Silver’ for Best Use of
        Technology at the Marketing PR Awards 2019. I was part of the team at
        AKA Asia that conceptualised ideas and designed the campaign's key
        visuals.
      </Text>

      <br />
      <Text>Full documentation coming soon. </Text>
      <br />
      <br />
      <br />

      <a
        href="https://cargocollective.com/dreworkz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PrimaryButton>
          <ButtonText variant="button" fontColor="rgba(255,255,255,0.87)">
            View Design Site
          </ButtonText>
        </PrimaryButton>
      </a>
      {"\xa0\xa0"}
      <a
        href="https://cargocollective.com/dreworkz/Hello-World-A-I-You-Ready"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GhostButton>
          <ButtonText variant="button" fontColor="rgba(0,0,255,0.87)">
            View this Project
          </ButtonText>
        </GhostButton>
      </a>
    </ProjectContainer>
  );
};

export default ProjectDisplay;
