import styled from "styled-components";
import { variant } from "styled-system";
import propTypes from "prop-types";

const variants = {
  bordered: {
    borderRadius: "4px",
    border: "1px solid #121731",
  },
};

const boxVariantList = Object.keys(variants);

const boxVariants = variant({
  variants,
});

const Box = styled.div`
  width: ${(props) => props.width};
  ${boxVariants};
`;

export default Box;

Box.defaultProps = {
  width: "fit-content",
};

Box.propTypes = {
  variant: propTypes.oneOf(boxVariantList),
  width: propTypes.string,
};
