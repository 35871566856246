import React from "react";
import styled from "styled-components";

import ButtonText from "../DLS/text";
import PrimaryButton from "../DLS/primary-button";
import GhostButton from "../DLS/ghost-button";

const ProjectContainer = styled.div`
  font-family: "Signika Negative";
  font-size: 15px;
  margin-top: 18px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Text = styled.div`
  font-weight: 400;
  line-height: 1.5;
`;

const ProjectDisplay = () => {
  return (
    <ProjectContainer>
      <Title>The MUBI Trailer</Title>
      <br />
      <Text>
        MUBI is a global, hand-curated cinema streaming and download service.
        Films range from cult classics to award-winning masterpieces.
      </Text>
      <br />
      <Text>
        The challenge was not just to reach film lovers, but people who are
        interested in all aspects of culture: art, music, design, architecture
        etc with the end goal of driving sign ups online.
      </Text>
      <br />
      <Text>
        What better way than do take this literally, having a MUBI Trailer
        driving people around town as they catch a movie? By strategically
        selecting the pick-up and drop-off points to be locations related to
        their movie screenings, MUBI turns reel life into real life, enhancing
        the movie experience.
      </Text>

      <br />
      <Text>
        The MUBI Trailer was a submission to answer the MUBI brief at the
        D&amp;AD New Blood Awards 2017. (Did not win but I'm still proud of it!)
      </Text>

      <br />
      <Text>Full documentation coming soon. </Text>
      <br />
      <br />
      <br />

      <a
        href="https://cargocollective.com/dreworkz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PrimaryButton>
          <ButtonText variant="button" fontColor="rgba(255,255,255,0.87)">
            View Design Site
          </ButtonText>
        </PrimaryButton>
      </a>
      {"\xa0\xa0"}
      <a
        href="https://cargocollective.com/dreworkz/The-MUBI-Trailer"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GhostButton>
          <ButtonText variant="button" fontColor="rgba(0,0,255,0.87)">
            View this Project
          </ButtonText>
        </GhostButton>
      </a>
    </ProjectContainer>
  );
};

export default ProjectDisplay;
