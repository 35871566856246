import React from "react";
import styled from "styled-components";

import HeadlineData from "./HeadlineData";
import "../App.css";

const Marquee = styled.div`
  background-color: white;
  margin: 0 auto;
  letter-spacing: 2;
  white-space: nowrap;
  overflow: hidden;
  height: 32px;
  border-bottom: 0.5px solid var(--primary-color);
  display: flex;
  align-content: center;

  span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 180s linear infinite;
    user-select: none;
    font-size: 15px;
    padding-top: 2px;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
`;

function TextScroll() {
  const headlineComponents = HeadlineData.map((HeadlineData) => HeadlineData);

  let longHeadlineStr = "Real Fake News: \xa0\xa0\xa0";

  for (const item of headlineComponents) {
    longHeadlineStr += item.text + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
  }

  return (
    <Marquee>
      <span>{longHeadlineStr}</span>
    </Marquee>
  );
}

export default TextScroll;
