import styled from "styled-components";

const PrimaryButton = styled.button`
  overflow: hidden;
  padding: 3px 8px 5px 8px;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;
  width: fit-content;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  outline: none;
  cursor: pointer;

  background: none;
  border: 1px solid rgba(0, 0, 0, 0.87);
  opacity: 72%;

  :hover {
    color: rgba(0, 0, 255, 1);
    border: 1px solid rgba(0, 0, 255, 1);
    background: none;
    opacity: 100%;
  }

  :active {
    opacity: 100%;
    background-color: rgba(0, 0, 255, 0.1);
  }

  &:disabled,
  &[disabled] {
    background: none;
    color: rgba(0, 0, 255, 1);
    border: 1px solid #4a6572;
    opacity: 30%;
    cursor: no-drop;
  }
`;

export default PrimaryButton;
