import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import {
    SliderWrapper,
    SliderIndicatorBar,
    SliderThumb,
    getValue,
    getPercentage,
    getLeft,
    roundByStep
} from './slider-base';

const SliderRange = props => {
    const {
        showValue,
        rangeMin,
        rangeMax,
        step,
        min,
        max,
        onRangeMinChange,
        onRangeMaxChange
    } = props;

    const sliderIndicatorRef = React.useRef();
    const sliderWrapperRef = React.useRef();
    const thumbMinRef = React.useRef();
    const thumbMaxRef = React.useRef();

    const diffMin = React.useRef();
    const diffMax = React.useRef();

    useEffect(() => {
        const range = rangeMax - rangeMin;
        sliderIndicatorRef.current.style.left = getLeft(
            getPercentage(rangeMin, min, max)
        );
        sliderIndicatorRef.current.style.width = `calc(${(range * 100) /
            (max - min)}%)`;

        const newPercentageMin = getPercentage(rangeMin, min, max);
        thumbMinRef.current.style.left = getLeft(newPercentageMin);

        const newPercentageMax = getPercentage(rangeMax, min, max);
        thumbMaxRef.current.style.left = getLeft(newPercentageMax);
    }, [rangeMin, rangeMax, max, min]);

    const handleMouseMoveMin = event => {
        let newX =
            event.clientX -
            diffMin.current -
            sliderWrapperRef.current.getBoundingClientRect().left;

        const end =
            sliderWrapperRef.current.offsetWidth -
            thumbMaxRef.current.offsetWidth;

        const start = min;

        if (newX < start) {
            newX = min;
        }

        if (newX > end) {
            newX = end;
        }

        if (step > 1) {
            const newPercentageMin = getPercentage(newX, start, end);
            const newValueMin = roundByStep(
                getValue(newPercentageMin, min, max),
                step
            );

            if (newValueMin >= rangeMax - step) {
                onRangeMinChange(rangeMax - step);
            } else {
                onRangeMinChange(newValueMin);
            }
        } else {
            const newPercentageMin = getPercentage(newX, start, end);
            const newValueMin = Math.round(
                getValue(newPercentageMin, min, max)
            );

            if (newValueMin >= rangeMax - step) {
                onRangeMinChange(rangeMax - step);
            } else {
                onRangeMinChange(newValueMin);
            }
        }
    };

    const handleMouseMoveMax = event => {
        let newX =
            event.clientX -
            diffMax.current -
            sliderWrapperRef.current.getBoundingClientRect().left;

        const end =
            sliderWrapperRef.current.offsetWidth -
            thumbMaxRef.current.offsetWidth;

        const start = min;

        if (newX < start) {
            newX = min;
        }

        if (newX > end) {
            newX = end;
        }

        if (step > 1) {
            const newPercentageMax = getPercentage(newX, start, end);
            const newValueMax = roundByStep(
                getValue(newPercentageMax, min, max),
                step
            );

            if (newValueMax <= rangeMin + step) {
                onRangeMaxChange(rangeMin + step);
            } else {
                onRangeMaxChange(newValueMax);
            }
        } else {
            const newPercentageMax = getPercentage(newX, start, end);
            const newValueMax = Math.round(
                getValue(newPercentageMax, min, max)
            );

            if (newValueMax <= rangeMin + step) {
                onRangeMaxChange(rangeMin + step);
            } else {
                onRangeMaxChange(newValueMax);
            }
        }
    };

    const handleMouseUpMin = () => {
        document.removeEventListener('mouseup', handleMouseUpMin);
        document.removeEventListener('mousemove', handleMouseMoveMin);
    };

    const handleMouseUpMax = () => {
        document.removeEventListener('mouseup', handleMouseUpMax);
        document.removeEventListener('mousemove', handleMouseMoveMax);
    };

    const handleMouseDownMin = event => {
        diffMin.current =
            event.clientX - thumbMinRef.current.getBoundingClientRect().left;

        document.addEventListener('mouseup', handleMouseUpMin);
        document.addEventListener('mousemove', handleMouseMoveMin);
    };

    const handleMouseDownMax = event => {
        diffMax.current =
            event.clientX - thumbMaxRef.current.getBoundingClientRect().left;

        document.addEventListener('mouseup', handleMouseUpMax);
        document.addEventListener('mousemove', handleMouseMoveMax);
    };

    return (
        <SliderWrapper ref={sliderWrapperRef} showValue={showValue}>
            <SliderIndicatorBar ref={sliderIndicatorRef} />
            <SliderThumb
                showValue={showValue}
                value={rangeMin}
                ref={thumbMinRef}
                onMouseDown={handleMouseDownMin}
                onMouseUp={handleMouseUpMin}
                onDrag={handleMouseMoveMin}
            />
            <SliderThumb
                showValue={showValue}
                value={rangeMax}
                ref={thumbMaxRef}
                onMouseDown={handleMouseDownMax}
                onMouseUp={handleMouseUpMax}
                onDrag={handleMouseMoveMax}
            />
        </SliderWrapper>
    );
};

export default SliderRange;

SliderRange.propTypes = {
    showValue: propTypes.bool,
    rangeMin: propTypes.number.isRequired,
    rangeMax: propTypes.number.isRequired,
    step: propTypes.number,
    min: propTypes.number.isRequired,
    max: propTypes.number.isRequired,
    onRangeMinChange: propTypes.func.isRequired,
    onRangeMaxChange: propTypes.func.isRequired
};

SliderRange.defaultProps = {
    step: 1,
    showValue: true
};
