import styled from "styled-components";
import propTypes from "prop-types";

const Stack = styled.div`
  justify-content: ${(props) => props.justifyContent};
  align-content: ${(props) => props.alignContent};
  display: flex;
  width: ${(props) => props.width};
  flex-direction: ${({ direction }) =>
    direction === "horizontal" ? "row" : "column"};

  > *:not(:first-child) {
    ${({ direction, spacing }) =>
      direction === "horizontal"
        ? `margin-left: ${spacing}`
        : `margin-top: ${spacing}`};
  }
`;

export default Stack;

Stack.defaultProps = {
  spacing: "8px",
  direction: "vertical",
};

Stack.propTypes = {
  width: propTypes.string,
  justifyContent: propTypes.string,
  alignContent: propTypes.string,
  spacing: propTypes.string,
  direction: propTypes.oneOf(["vertical", "horizontal"]),
};
