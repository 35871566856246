import React from "react";
import styled from "styled-components";

const ProjectContainer = styled.div`
  font-family: "Signika Negative";
  font-size: 15px;
  margin-top: 18px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Text = styled.div`
  font-weight: 400;
  line-height: 1.5;
`;

const ProjectDisplay = () => {
  return (
    <ProjectContainer>
      <Title>About Drew</Title>
      <br />
      <br />
      <Text>
        <em>EDUCATION</em>
        <br />
        SIM – University of Wollongong
        <br />
        Bachelor of Computer Science
        <br />
        Multimedia &amp; Game Development (2017–2020)
        <br />
        <br />
        Temasek Polytechnic School of Design
        <br />
        Diploma in Visual Communication (2011–2014)
      </Text>
      <br />
      <br />
      <Text>
        <em>WORK</em>
        <br />
        Accenture Interactive
        <br />
        UX &amp; Front-end Intern (Jan–Apr 2020)
        <br />
        <br />
        AKA Asia
        <br />
        Freelance Creative (Dec 2017–Dec 2018)
        <br />
        <br />
        Kinetic Singapore
        <br />
        Creative Intern (Sept 2016–Apr 2017)
        <br />
        <br />
        Epigram
        <br />
        Design Intern (Jul–Oct 2013)
        <br />
      </Text>
      <br />
      <br />
      <Text>
        <em>AWARDS</em>
        <br />
        Marketing PR Awards 2019
        <br />
        Best Use of Technology (Silver)
        <br />
        Hello World. A.I. You Ready?
        <br />
        Client: Singapore Science Festival 2018
        <br />
        Agency: AKA Asia
        <br />
        <br />
        Creative Circle Awards 2016
        <br />
        NexGen Challenge (Silver)
        <br />
        Emoji Data
        <br />
        Client: Singtel Media
        <br />
        Agency: Kinetic Singapore
        <br />
        <br />
      </Text>
    </ProjectContainer>
  );
};

export default ProjectDisplay;
