/* eslint-disable react/no-unused-prop-types */
import propTypes from "prop-types";
import styled from "styled-components";

const ColorDot = styled.div`
  overflow: hidden;
  border-radius: 50%;
  outline: none;

  background-color: ${(props) => props.bg};
  height: ${(props) => props.height};
  width: ${(props) => props.width};

  :active {
    cursor: pointer;
  }

  &:disabled,
  [disabled] {
    cursor: no-drop;
  }
`;

export default ColorDot;

ColorDot.defaultProps = {
  bg: "black",
  height: "24px",
  width: "24px",
};

ColorDot.propTypes = {
  bg: propTypes.string,
  height: propTypes.string,
  width: propTypes.string,
};
