import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  height = "16",
  width = "16",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M1.75 0C0.783508 0 0 0.783501 0 1.75V14.25C0 15.2165 0.783508 16 1.75 16H14.25C15.2165 16 16 15.2165 16 14.25V1.75C16 0.783501 15.2165 0 14.25 0H1.75ZM3.13196 5.37281C3.25049 5.10603 3.45367 4.87951 3.71417 4.72371C3.97473 4.56791 4.28009 4.49029 4.58923 4.50129H11.3859C11.6909 4.48922 11.9929 4.56231 12.2532 4.71124C12.5134 4.86016 12.7203 5.07815 12.8472 5.33736C12.8726 5.37028 12.8892 5.40841 12.8956 5.44855C12.9019 5.48869 12.8979 5.52967 12.8838 5.56806C12.8697 5.60645 12.8459 5.64112 12.8145 5.66917C12.7831 5.69722 12.745 5.71782 12.7032 5.72926L8.19745 7.92581C8.14056 7.95626 8.07611 7.97228 8.01056 7.97228C7.94495 7.97228 7.88055 7.95626 7.82367 7.92581L3.25787 5.7106C3.22223 5.70032 3.1897 5.68237 3.1629 5.6582C3.13605 5.63402 3.11572 5.6043 3.10345 5.57142C3.09119 5.53855 3.0874 5.50345 3.09235 5.46895C3.09729 5.43445 3.11084 5.40152 3.13196 5.37281ZM3.42981 6.77435C4.84912 7.46734 6.26709 8.15784 7.68372 8.84586C7.77905 8.89857 7.88776 8.92639 7.99854 8.92639C8.10938 8.92639 8.21808 8.89857 8.31342 8.84586C9.72339 8.15535 11.1354 7.47107 12.5493 6.79301C12.5733 6.78056 12.5984 6.77003 12.6243 6.76154C12.6429 6.75541 12.6619 6.75034 12.6813 6.74635C12.7189 6.73432 12.7592 6.7313 12.7984 6.73756C12.8376 6.74381 12.8746 6.75916 12.9058 6.78218C12.937 6.80521 12.9615 6.83517 12.9772 6.86934C12.9927 6.90351 12.999 6.94079 12.9951 6.97777V9.61474C13.0066 9.8427 12.9979 10.0712 12.9691 10.2978C12.9124 10.6363 12.7266 10.9442 12.446 11.165C12.1654 11.3858 11.8088 11.5047 11.4419 11.4996H4.58521C4.23853 11.507 3.89929 11.4051 3.62219 11.2104C3.34515 11.0157 3.14636 10.7396 3.05798 10.4266C3.02148 10.305 3.00201 10.1795 3 10.0533V7.00576C3 6.75009 3.1759 6.65305 3.42981 6.77435Z"
    />
  </svg>
);

export default SVG;
