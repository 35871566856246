import React, { useState } from "react";
import styled from "styled-components";

import Text from "./text";
import PrimaryButton from "./primary-button";
import SecondaryButton from "./secondary-button";
import TertiaryButton from "./tertiary-button";
import GhostButton from "./ghost-button";

import Stack from "./stack";
import Slider from "./slider";
import SliderRange from "./slider-range";
import Box from "./box";

import ColorPicker from "./color-picker";
import ColorDot from "./color-dot";

const ProjectContainer = styled.div`
  font-family: "Signika Negative";
  font-size: 15px;
  margin-top: 18px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const BodyCopy = styled.div`
  font-weight: 400;
  line-height: 1.5;
`;

const ProjectDisplay = () => {
  const [buttonClicked, setButtonClicked] = useState("None");

  const [value, setValue] = useState(10);

  const [rangeMin, setRangeMin] = useState(20);
  const [rangeMax, setRangeMax] = useState(50);

  const [selectedColors, setSelectedColors] = useState([]);
  const colorArray = ["Red", "Blue", "Gray", "White", "Black"];

  function toggleColor(color) {
    const newSelectedColors = selectedColors.slice(0);
    if (newSelectedColors.includes(color)) {
      setSelectedColors(newSelectedColors.filter((c) => c !== color));
    } else {
      newSelectedColors.push(color);
      setSelectedColors(newSelectedColors);
    }
  }

  function showColors(a) {
    const b = a.slice(0);
    let str = " ";
    for (const item of b) {
      str += item + " ";
    }
    return str;
  }

  function isEmpty(a) {
    const b = a.slice(0);

    if (b.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <ProjectContainer>
      <Title>Design Language System</Title>
      <br />
      <BodyCopy>
        I created a mini Design Language System for my site loosely based on the
        work I did at Accenture Interactive. Examples below are reusable React
        components handling state using hooks.
      </BodyCopy>
      <br />
      <br />
      <BodyCopy>Buttons</BodyCopy>
      <br />
      <div>{`Clicked: ${buttonClicked}`}</div>
      <br />
      <Stack direction="horizontal">
        <PrimaryButton onClick={() => setButtonClicked("Primary")}>
          <Text variant="button" fontColor="rgba(255,255,255,0.87)">
            Primary Button
          </Text>
        </PrimaryButton>

        <SecondaryButton onClick={() => setButtonClicked("Secondary")}>
          <Text variant="button" fontColor="rgba(255,255,255,0.87)">
            Secondary Button
          </Text>
        </SecondaryButton>

        <TertiaryButton onClick={() => setButtonClicked("Tertiary")}>
          <Text variant="button" fontColor="rgba(0,0,255,0.87)">
            Tertiary Button
          </Text>
        </TertiaryButton>

        <GhostButton onClick={() => setButtonClicked("Ghost")}>
          <Text variant="button" fontColor="rgba(0,0,255,0.87)">
            Ghost Button
          </Text>
        </GhostButton>
      </Stack>

      <br />
      <Stack direction="horizontal">
        <PrimaryButton
          disabled
          onMouseOver={() => setButtonClicked("Primary Disabled (Hovered)")}
        >
          <Text variant="button" fontColor="rgba(0,0,255,1)">
            Primary Button
          </Text>
        </PrimaryButton>

        <SecondaryButton
          disabled
          onMouseOver={() => setButtonClicked("Secondary Disabled (Hovered)")}
        >
          <Text variant="button" fontColor="#4a6572">
            Secondary Button
          </Text>
        </SecondaryButton>

        <TertiaryButton
          disabled
          onMouseOver={() => setButtonClicked("Tertiary Disabled (Hovered)")}
        >
          <Text variant="button" fontColor="#4a6572">
            Tertiary Button
          </Text>
        </TertiaryButton>

        <GhostButton
          disabled
          onMouseOver={() => setButtonClicked("Ghost Disabled (Hovered)")}
        >
          <Text variant="button" fontColor="#4a6572">
            Ghost Button
          </Text>
        </GhostButton>
      </Stack>

      <br />
      <br />
      <br />

      <BodyCopy>Sliders</BodyCopy>
      <div>
        <br />
        <div>Single value slider (step of 5) — {`Current value: ${value}`}</div>
        <Box padding="4px 24px" overflow="visible" width="80%">
          <Slider
            value={value}
            step={5}
            min={0}
            max={100}
            onChange={(newValue) => setValue(newValue)}
          />
          <Stack
            direction="horizontal"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <div>{0}</div>
            <div>{100}</div>
          </Stack>
        </Box>
        <br />
        <div>
          Range slider (step of 10) — {`Min: ${rangeMin}`}, {`Max: ${rangeMax}`}
        </div>

        <Box padding="4px 24px" overflow="visible" width="80%">
          <SliderRange
            rangeMin={rangeMin}
            rangeMax={rangeMax}
            step={10}
            min={0}
            max={100}
            onRangeMinChange={(newRangeMin) => setRangeMin(newRangeMin)}
            onRangeMaxChange={(newRangeMax) => setRangeMax(newRangeMax)}
          />
          <Stack
            direction="horizontal"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <div>{0}</div>
            <div>{100}</div>
          </Stack>
        </Box>
        <br />
      </div>

      <br />
      <br />

      <BodyCopy>Color Picker</BodyCopy>
      <br />
      <div>
        Currently Selected:
        {isEmpty(selectedColors) ? " None" : showColors(selectedColors)}
      </div>
      <br />
      <div>
        <Stack direction="horizontal" spacing="8px" flexWrap="wrap">
          {colorArray &&
            colorArray.length > 0 &&
            colorArray.map((color) => {
              return (
                <ColorPicker
                  key={color}
                  isSelected={selectedColors.includes(color)}
                  onClick={() => toggleColor(color)}
                  height="28px"
                  width="28px"
                >
                  <ColorDot bg={color} height="20px" width="20px" />
                </ColorPicker>
              );
            })}
        </Stack>
      </div>

      <br />
      <br />
      <br />
    </ProjectContainer>
  );
};

export default ProjectDisplay;
