import styled from "styled-components";

import PrimaryButton from "./primary-button";

const TertiraryButton = styled(PrimaryButton)`
  background: none;
  border: 1px solid rgba(0, 0, 255, 1);
  opacity: 72%;

  :hover {
    background: none;
    opacity: 100%;
  }

  :active {
    opacity: 100%;
    background-color: rgba(0, 0, 255, 0.1);
    color: white;
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #4a6572;
    background: none;
    opacity: 30%;
  }
`;

export default TertiraryButton;
