import React from "react";
import styled from "styled-components";

import { Col, Container } from "reactstrap";

// import Mail from "../svg/email";

const FooterBarContainer = styled(Container)`
  /* height: 40px; */
  margin: 0;
`;

const TextStyled = styled.div`
  font-family: "Signika Negative", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  text-decoration: none;

  margin-bottom: 16px;

  /* display: flex;
  align-items: center;
  width: fit-content; */
  /* cursor: pointer; */

  /* :hover {
    color: rgba(0, 0, 255, 0.87);
    transition: 0.25s ease-in-out;
  } */
`;

// const PlaceHolderBox = styled.div`
//   :before {
//     content: "";
//     display: block;
//     /* width: 36%; */
//     /* border-top: 0.5px solid rgba(0, 0, 0, 0.87); */
//   }
// `;

function App() {
  // const [hoverOne, setHoverOne] = useState(false);

  return (
    <Col>
      <FooterBarContainer>
        <TextStyled>Drew, 2020</TextStyled>
      </FooterBarContainer>
    </Col>
  );

  /* <PlaceHolderBox> */
  /* <SVGStyled
          href="mailto:dreworkz@gmail.com"
          onMouseEnter={() => setHoverOne(true)}
          onMouseLeave={() => setHoverOne(false)}
        >
          <Mail
            name="email"
            height="15px"
            width="15px"
            fill={hoverOne ? "rgba(0, 0, 255, 0.87)" : "rgba(0, 0, 0, 0.87)"}
          />
          <Shift8>dreworkz@gmail.com</Shift8>
        </SVGStyled> */
  /* </PlaceHolderBox> */
}

export default App;
