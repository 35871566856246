import styled from "styled-components";

import PrimaryButton from "./primary-button";

const GhostButton = styled(PrimaryButton)`
  background: none;
  opacity: 72%;

  :hover {
    background: none;
    opacity: 100%;
  }

  :active {
    opacity: 100%;
    background-color: rgba(0, 0, 255, 0.1);
  }

  &:disabled,
  &[disabled] {
    background: none;
    border: none;
    opacity: 30%;
  }
`;

export default GhostButton;
