/* eslint-disable react/no-unused-prop-types */
import propTypes from "prop-types";
import styled from "styled-components";

const ColorPicker = styled.div`
  overflow: hidden;
  cursor: pointer;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 2px solid transparent; */
  border: 2px solid transparent;
  border-color: ${(props) =>
    props.isSelected ? "rgba(0, 0, 255, 0.87)" : "none"};
  border-radius: 50%;

  :hover {
    border-color: rgba(0, 0, 255, 0.6);
  }

  :active {
    opacity: 87%;
  }

  &:disabled,
  [disabled] {
    border: transparent;
    cursor: no-drop;
  }
`;

export default ColorPicker;

ColorPicker.defaultProps = {
  height: "32px",
  width: "32px",
};

ColorPicker.propTypes = {
  height: propTypes.string,
  width: propTypes.string,
};
