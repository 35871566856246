import styled from "styled-components";

export const SliderWrapper = styled.div`
  position: relative;
  border-radius: 3px;
  background: rgba(74, 101, 114, 0.5);
  height: 4px;
  margin: 8px;
  margin-top: ${(props) => (props.showValue ? "40px" : "8px")};
`;

export const SliderIndicatorBar = styled.div`
  position: relative;
  width: auto;
  border-radius: 3px;
  background: rgba(0, 0, 255, 0.87);
  height: 4px;
  transition: all 300ms linear;
`;

export const SliderThumb = styled.div`
    &::after {
        content: '${(props) => props.value}';
        display: ${(props) => (props.showValue ? "block" : "none")};
        position: absolute;
        top: calc(-100% - 24px);
        padding: 4px 8px;
        background: rgba(0, 0, 0, 0.87);
        border-radius: 4px;
        font-family: "Signika Negative";
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0.32px;
        text-align: center;
        line-height: 16px;
    }

    border: solid 2px rgba(0, 0, 0, 0.87);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: -6px;
    background: #e3e4e7;
    cursor: pointer;
    transform: translateX(-50%);

    transition: all 300ms linear;

    :hover {
        background: white;
    }

    :active {
        border-color: rgba(0, 0, 255, 0.87);
    }
`;

export const getPercentage = (current, min, max) =>
  ((current - min) / (max - min)) * 100;

export const getValue = (percentage, min, max) =>
  ((max - min) / 100) * percentage + min;

export const getLeft = (percentage) => `calc(${percentage}%)`;

export const roundByStep = (number, step) => {
  if (number === 0) {
    return 0;
  }
  return Math.round(number / step) * step;
};
