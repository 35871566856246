import styled from "styled-components";

import PrimaryButton from "./primary-button";

const SecondaryButton = styled(PrimaryButton)`
  background-color: #344955;

  :hover {
    background-color: #232f34;
  }

  :active {
    background-color: rgba(74, 101, 114, 1);
  }

  &:disabled,
  &[disabled] {
    border: 1px solid #4a6572;
    background: none;
  }
`;

export default SecondaryButton;
