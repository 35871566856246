import React from "react";
import styled from "styled-components";

import ButtonText from "../DLS/text";
import PrimaryButton from "../DLS/primary-button";
import GhostButton from "../DLS/ghost-button";

const ProjectContainer = styled.div`
  font-family: "Signika Negative";
  font-size: 15px;
  margin-top: 18px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Text = styled.div`
  font-weight: 400;
  line-height: 1.5;
`;

const ProjectDisplay = () => {
  return (
    <ProjectContainer>
      <Title>Animations</Title>
      {/* <br />
      <Text>
        With the widest consumer reach in Singapore, Singtel Media boasts
        powerful Big Data that supplies accurate audience targeting for their
        clients. Data is a big word that can be anything &amp; everything. We
        found similarities in the world of Emojis, where just about everything
        is represented with an icon.
      </Text>
      <br />
      <Text>
        Through Emojis, we make Data fun and get people involved to experience
        the power of Singtel Media's Big Data for themselves.
      </Text>
      <br />
      <Text>
        Singtel Media Emoji Data was awarded a ‘Silver’ at the 2016 Singapore
        Creative Circle Awards NexGen Challenge. This project was done in
        collaboration with Sean Tan at Kinetic Singapore.
      </Text> */}

      <br />
      <Text>Full documentation coming soon. </Text>
      <br />
      <br />
      <br />

      <a
        href="https://cargocollective.com/dreworkz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PrimaryButton>
          <ButtonText variant="button" fontColor="rgba(255,255,255,0.87)">
            View Design Site
          </ButtonText>
        </PrimaryButton>
      </a>
      {"\xa0\xa0"}
      <a
        href="https://cargocollective.com/dreworkz/Animations"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GhostButton>
          <ButtonText variant="button" fontColor="rgba(0,0,255,0.87)">
            View this Project
          </ButtonText>
        </GhostButton>
      </a>
    </ProjectContainer>
  );
};

export default ProjectDisplay;
