import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";

import FilterButton from "./FilterButton";
import Stack from "./Stacker";

import { Container, Col } from "reactstrap";

const FilterContainer = styled(Container)`
  margin-top: 56px;
  outline: none;
`;

const ButtonText = styled.p`
  font-family: "Signika Negative", sans-serif;
  height: 2px;
  width: fit-content;
  margin-top: 2px;
  font-size: 12.4px;

  :active {
    color: rgba(0, 0, 255, 0.87);
  }
`;

const ResetText = styled.span`
  font-family: "Signika Negative", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 255, 0.87);
  align-self: center;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  padding-left: 12px;

  :hover {
    font-weight: 700;
  }
`;

const FilterBar = () => {
  const [activeProject, setActiveProject] = useGlobal("activeProject");

  return (
    <Col xs="12" sm="9" md="8" lg="8">
      <FilterContainer>
        <Stack direction="horizontal">
          <FilterButton
            onClick={() => setActiveProject("All")}
            style={{
              color: activeProject === "All" && "rgba(255,255,255,0.87)",
              background: activeProject === "All" && "#344955",
              opacity: activeProject === "All" && "100%",
              pointerEvents: activeProject === "All" && "none",
              border: activeProject === "All" && "1px solid #344955",
              outline: "none",
            }}
          >
            <ButtonText>View all</ButtonText>
          </FilterButton>

          <FilterButton
            onClick={() => setActiveProject("Des")}
            style={{
              color: activeProject === "Des" && "rgba(255,255,255,0.87)",
              background: activeProject === "Des" && "#344955",
              opacity: activeProject === "Des" && "100%",
              pointerEvents: activeProject === "Des" && "none",
              border: activeProject === "Des" && "1px solid #344955",
              outline: "none",
            }}
          >
            <ButtonText>Design</ButtonText>
          </FilterButton>

          <FilterButton
            onClick={() => setActiveProject("Dev")}
            style={{
              color: activeProject === "Dev" && "rgba(255,255,255,0.87)",
              background: activeProject === "Dev" && "#344955",
              opacity: activeProject === "Dev" && "100%",
              pointerEvents: activeProject === "Dev" && "none",
              border: activeProject === "Dev" && "1px solid #344955",
              outline: "none",
            }}
          >
            <ButtonText>Development</ButtonText>
          </FilterButton>

          <FilterButton
            onClick={() => setActiveProject("Ani")}
            style={{
              color: activeProject === "Ani" && "rgba(255,255,255,0.87)",
              background: activeProject === "Ani" && "#344955",
              opacity: activeProject === "Ani" && "100%",
              pointerEvents: activeProject === "Ani" && "none",
              border: activeProject === "Ani" && "1px solid #344955",
              outline: "none",
            }}
          >
            <ButtonText>Animation</ButtonText>
          </FilterButton>

          <ResetText
            onClick={() => setActiveProject("All")}
            style={{
              visibility: activeProject === "All" ? "hidden" : "visible",
            }}
          >
            Reset
          </ResetText>
        </Stack>
      </FilterContainer>
    </Col>
  );
};

export default FilterBar;
