import React, { useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";

import "../App.css";

import Stacker from "./Stacker";

import Instagram from "../svg/instagram";
import LinkedIn from "../svg/linkedin";
import Mail from "../svg/mail";
import Smiley from "../svg/smiley";

import {
  Col,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  // NavLink,
  // NavbarToggler,
  // Collapse,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // NavbarText,
  // Row,
  // Container,
} from "reactstrap";

const HeaderBarContainer = styled(Navbar)`
  align-items: center;
  padding-top: 12px;
  padding-bottom: 20px;

  background: linear-gradient(
    var(--background-color),
    var(--background-color),
    rgba(0, 0, 0, 0)
  );

  background: -webkit-linear-gradient(
    var(--background-color),
    var(--background-color),
    rgba(0, 0, 0, 0)
  );
`;

const SVGStyled = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;

  border-bottom: 0px solid #eeedee;

  :hover {
    transition: 0.25s ease-in-out;
  }
`;

const LinkStyled = styled.span`
  color: var(--primary-color);
  font-family: "Signika Negative", sans-serif;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  :hover {
    color: var(--secondary-color);
  }
`;

const NavBar = () => {
  const [hoverLogo, setHoverLogo] = useState(false);
  const [hoverZero, setHoverZero] = useState(false);
  const [hoverOne, setHoverOne] = useState(false);
  const [hoverTwo, setHoverTwo] = useState(false);

  const [activeProject, setActiveProject] = useGlobal("activeProject");

  return (
    <HeaderBarContainer>
      <Col xs="7" sm="6" md="5" lg="4">
        <NavbarBrand
          href="/"
          navbar
          onMouseEnter={() => setHoverLogo(true)}
          onMouseLeave={() => setHoverLogo(false)}
        >
          <Stacker direction="horizontal" alignContent="center">
            <Smiley
              height="56px"
              width="auto"
              fill={
                hoverLogo ? "var(--secondary-color)" : "var(--primary-color)"
              }
            />
          </Stacker>
        </NavbarBrand>
      </Col>

      <Col xs="auto" sm="auto" md="auto">
        <Nav navbar>
          <Stacker direction="horizontal">
            <Col style={{ marginLeft: "0", paddingLeft: "0" }}>
              <NavItem>
                <LinkStyled onClick={() => setActiveProject(0)}>
                  CV / ABOUT
                </LinkStyled>
              </NavItem>
            </Col>

            <NavItem>
              <SVGStyled
                href="mailto:dreworkz@gmail.com"
                // target="_blank"
                // rel="noopener noreferrer"
                onMouseEnter={() => setHoverZero(true)}
                onMouseLeave={() => setHoverZero(false)}
              >
                <Mail
                  width="20px"
                  fill={
                    hoverZero
                      ? "var(--secondary-color)"
                      : "var(--primary-color)"
                  }
                />
              </SVGStyled>
            </NavItem>

            <NavItem>
              <SVGStyled
                href="https://www.linkedin.com/in/dreworkz/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoverOne(true)}
                onMouseLeave={() => setHoverOne(false)}
              >
                <LinkedIn
                  width="20px"
                  fill={
                    hoverOne ? "var(--secondary-color)" : "var(--primary-color)"
                  }
                />
              </SVGStyled>
            </NavItem>

            <NavItem>
              <SVGStyled
                href="https://www.instagram.com/dreworkz/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoverTwo(true)}
                onMouseLeave={() => setHoverTwo(false)}
              >
                <Instagram
                  width="20px"
                  fill={
                    hoverTwo ? "var(--secondary-color)" : "var(--primary-color)"
                  }
                />
              </SVGStyled>
            </NavItem>
          </Stacker>
        </Nav>
      </Col>
    </HeaderBarContainer>
  );
};

export default NavBar;
