import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import {
    SliderWrapper,
    SliderIndicatorBar,
    SliderThumb,
    getValue,
    getPercentage,
    getLeft,
    roundByStep
} from './slider-base';

const Slider = props => {
    const { showValue, value, step, min, max, onChange } = props;

    const sliderRef = React.useRef();
    const sliderProgressRef = React.useRef();
    const thumbRef = React.useRef();

    const diff = React.useRef();

    useEffect(() => {
        const range = value - min;
        sliderRef.current.style.width = `calc(${(range * 100) / (max - min)}%)`;

        const newPercentage = getPercentage(value, min, max);
        thumbRef.current.style.left = getLeft(newPercentage);
    }, [value, max, min]);

    const handleMouseMove = event => {
        let newX =
            event.clientX -
            diff.current -
            sliderProgressRef.current.getBoundingClientRect().left;

        const end =
            sliderProgressRef.current.offsetWidth -
            thumbRef.current.offsetWidth;

        const start = min;

        if (newX < start) {
            newX = min;
        }

        if (newX > end) {
            newX = end;
        }

        const newPercentage = getPercentage(newX, start, end);
        const newValue = roundByStep(getValue(newPercentage, min, max), step);

        onChange(newValue);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
    };

    const handleMouseDown = event => {
        diff.current =
            event.clientX - thumbRef.current.getBoundingClientRect().left;

        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
    };

    return (
        <SliderWrapper ref={sliderProgressRef} showValue={showValue}>
            <SliderIndicatorBar ref={sliderRef} />
            <SliderThumb
                showValue={showValue}
                value={value}
                ref={thumbRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onDrag={handleMouseMove}
            />
        </SliderWrapper>
    );
};

export default Slider;

Slider.propTypes = {
    showValue: propTypes.bool,
    value: propTypes.number.isRequired,
    step: propTypes.number,
    min: propTypes.number.isRequired,
    max: propTypes.number.isRequired,
    onChange: propTypes.func.isRequired
};

Slider.defaultProps = {
    step: 1,
    showValue: true
};
