import React from "react";
import styled from "styled-components";

// import Responsive from "../sizing/Responsive";

import TextScroll from "./TextScroll";
import Navbar from "./NavBar";
import Subhead from "./SubHead";
import FilterBar from "./FilterBar";
import Body from "./Body";
import Footer from "./FooterBar";

import "../App.css";

const Page = styled.div`
  width: 100%;
  height: 100%;
  font-family: var(--primary-font), sans-serif;
  font-weight: 400;
  color: var(--primary-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-decoration: none;
  user-select: none;
  outline: none;
  overflow: scroll;

  /* unvisited link */
  a:link {
    text-decoration: none;
  }

  /* visited link */
  a:visited {
    text-decoration: none;
  }

  /* mouse over link */
  a:hover {
    text-decoration: none;
  }

  /* selected link */
  a:active {
    text-decoration: none;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
`;

const Foot = styled.div`
  clear: both;
  position: fixed;

  bottom: 0;
  left: 0;
`;

function App() {
  return (
    <Page>
      {/* <Responsive displayIn={["Laptop", "Tablet"]}> */}
      <div>
        <Sticky>
          <TextScroll />
          <Navbar />
        </Sticky>

        <Subhead />
        <FilterBar />
        <Body />

        <Foot>
          <Footer />
        </Foot>
      </div>
      {/* </Responsive> */}

      {/* <Responsive displayIn={["Mobile"]}>
        <Ticker>
          <TextScroll />
        </Ticker>
      </Responsive> */}
    </Page>
  );
}

export default App;
