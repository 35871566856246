import styled from "styled-components";

const PrimaryButton = styled.button`
  overflow: hidden;
  color: white;
  padding: 12px;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  width: fit-content;
  background-color: rgba(0, 0, 255, 1);
  cursor: pointer;
  outline: none;

  :hover {
    background-color: rgba(0, 0, 202, 1);
  }

  :active {
    background-color: rgba(0, 0, 255, 0.87);
  }

  &:disabled,
  &[disabled] {
    background: none;
    color: rgba(0, 0, 255, 1);
    border: 1px solid rgba(0, 0, 255, 0.87);
    opacity: 30%;
    cursor: no-drop;
  }
`;

export default PrimaryButton;
