import React from "react";
import { useGlobal } from "reactn";

import propTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  width: "20px";

  cursor: pointer;

  :hover {
    color: rgba(0, 0, 255, 0.87);
  }
`;

const Text = styled.p`
  font-family: "Signika Negative", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-top: 8px;
  /* text-transform: uppercase; */
  text-align: center;
`;

const Image = (props) => {
  const [activeProject, setActiveProject] = useGlobal("activeProject");

  const { height, width, src, alt, text, proj } = props;
  return (
    <Container>
      <img
        src={src}
        height={height}
        width={width}
        alt={alt}
        onClick={() => setActiveProject(proj)}
        style={{ borderRadius: "2%", border: "1px solid rgba(0,0,0,0.5)" }}
      />

      <Text>{text}</Text>
    </Container>
  );
};

export default Image;

Image.defaultProps = {
  height: "auto",
  width: "100%",
  alt: "thumbnail",
  text: "Text",
};

Image.propTypes = {
  height: propTypes.string,
  width: propTypes.string,
  src: propTypes.string.isRequired,
  alt: propTypes.string,
  text: propTypes.string,
  proj: propTypes.string,
};
