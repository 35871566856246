import styled from "styled-components";
import { variant } from "styled-system";
import propTypes from "prop-types";

import "../../App.css";

const variants = {
  button: {
    fontSize: "15px",
  },
};

const TextVariantList = Object.keys(variants);

const TextVariants = variant({
  variants,
});

const Text = styled.span`
  color: ${(props) => props.fontColor};
  font-family: var(--secondary-font);
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: "0.16px";
  outline: 0;
  user-select: ${(props) => props.selectable};
  text-align: right;

  &:disabled,
  &[disabled] {
    opacity: 30%;
  }

  ${TextVariants};
`;

export default Text;

Text.defaultProps = {
  selectable: "none",
  fontColor: "rgba(0,0,0,0.87)",
};

Text.propTypes = {
  theme: propTypes.object,

  fontColor: propTypes.string,
  variant: propTypes.oneOf(TextVariantList),
  selectable: propTypes.string,
};
