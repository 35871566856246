import React from "react";
import styled from "styled-components";

import { Col, Container } from "reactstrap";

const SubHeadText = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 5vw;
  line-height: 97.8%;
`;

const SubHead = () => {
  return (
    <Col xs="11" sm="9" md="7" lg="7">
      <Container
        style={{
          textAlign: "left",
          margin: "0",
        }}
      >
        <SubHeadText>
          Drew is a Fresh-Grad Developer with a keen eye for Design.
        </SubHeadText>
      </Container>
    </Col>
  );
};

export default SubHead;
