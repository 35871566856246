import React from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";

import DefaultProj from "./ProjectDisplay";
import Thumbnail from "./Thumbnail";

import DLS from "../projects/DLS/Mini-DLS";
import SSF from "../projects/SSF/SSF";
import Mubi from "../projects/mubi-trailer/Mubi";
import EmojiData from "../projects/emoji-data/Emoji-Data";
import Animation from "../projects/animation/Animation";

import { Col, Row } from "reactstrap";

const OverflowContainer = styled(Row)`
  overflow: auto;
  margin-top: 30px;
  margin-bottom: 72px;
  margin-left: 0px;
  margin-right: 0px;

  padding-left: 16px;
  padding-right: 16px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Body = () => {
  const [activeProject, setActiveProject] = useGlobal("activeProject");

  function whichActive(a) {
    switch (a) {
      case 0:
        return (
          <OverflowContainer>
            <Col xs="12" sm="10" md="8">
              <DefaultProj />
            </Col>
          </OverflowContainer>
        );
      case "All":
        return (
          <OverflowContainer>
            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/dls.png"
                text="Mini DLS"
                proj="Dev1"
              />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/mubi.png"
                text="The MUBI Trailer"
                proj="Des2"
              />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/emoji.png"
                text="Emoji Data"
                proj="Des3"
              />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail src="/thumbnails/ssf.png" text="SSF '18" proj="Des1" />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/ani.png"
                text="Animations"
                proj="Des4"
              />
            </Col>
          </OverflowContainer>
        );

      case "Des":
        return (
          <OverflowContainer>
            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/mubi.png"
                text="The MUBI Trailer"
                proj="Des2"
              />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/emoji.png"
                text="Emoji Data"
                proj="Des3"
              />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail src="/thumbnails/ssf.png" text="SSF '18" proj="Des1" />
            </Col>

            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/ani.png"
                text="Animations"
                proj="Des4"
              />
            </Col>
          </OverflowContainer>
        );

      case "Dev":
        return (
          <OverflowContainer>
            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/dls.png"
                text="Mini DLS"
                proj="Dev1"
              />
            </Col>
          </OverflowContainer>
        );

      case "Ani":
        return (
          <OverflowContainer>
            <Col xs="6" sm="6" md="4">
              <Thumbnail
                src="/thumbnails/ani.png"
                text="Animations"
                proj="Des4"
              />
            </Col>
          </OverflowContainer>
        );

      case "Dev1":
        return (
          <OverflowContainer>
            <Col xs="12" sm="10" md="8">
              <DLS />
            </Col>
          </OverflowContainer>
        );
      case "Des1":
        return (
          <OverflowContainer>
            <Col xs="12" sm="10" md="8">
              <SSF />
            </Col>
          </OverflowContainer>
        );
      case "Des2":
        return (
          <OverflowContainer>
            <Col xs="12" sm="10" md="8">
              <Mubi />
            </Col>
          </OverflowContainer>
        );
      case "Des3":
        return (
          <OverflowContainer>
            <Col xs="12" sm="10" md="8">
              <EmojiData />
            </Col>
          </OverflowContainer>
        );
      case "Des4":
        return (
          <OverflowContainer>
            <Col xs="12" sm="10" md="8">
              <Animation />
            </Col>
          </OverflowContainer>
        );

      default:
        return null;
    }
  }
  return <div>{whichActive(activeProject)}</div>;
};

export default Body;
